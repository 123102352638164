import React, { FC } from 'react';
import parse from 'html-react-parser';
import Button from '../common/Buttons/Button';
import Link from '../Link';
import TruncatedHtml from '../common/TruncatedHtml/TruncatedHtml';
interface props {
  blog?: any;
  isRelatedPage?: boolean;
}
const BlogCard: FC<props> = ({ blog, isRelatedPage }) => {
  return (
    <div
      className={`blog ${
        isRelatedPage
          ? 'lg:w-1/2 min-w-[49%] md:p-0 p-0'
          : 'lg:w-1/3  md:p-3 p-2 w-full'
      }   cursor-pointer `}
    >
      <div
        className={`xl:h-[314px] h-[200px] w-full rounded-[21px] relative ${
          isRelatedPage ? '' : ' '
        }`}
      >
        <Link preserveQueryParams={true} to={`/blog/${blog.slug}`}>
          {blog?.featuredImage?.node?.publicUrl ? (
            <img
              src={
                blog?.featuredImage?.node?.publicUrl ||
                `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`
              }
              alt="Spartan Peptide"
              width={243}
              height={279}
              className={`blog_image w-full h-full ${
                !blog?.featuredImage?.node?.publicUrl
                  ? 'object-contain bg-gray-300'
                  : 'object-cover'
              }  rounded-[21px]`}
            />
          ) : (
            <img
              src={`${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`}
              alt="Spartan Peptide"
              width={243}
              height={279}
              className={`blog_image w-full h-full ${
                !blog?.featuredImage?.node?.publicUrl
                  ? 'object-contain bg-gray-300'
                  : 'object-cover'
              }  rounded-[21px]`}
            />
          )}
          {blog.categories.nodes ? (
            <div className="blog_categories absolute left-2 bottom-3">
              <ul className="flex flex-wrap  gap-2">
                {blog.categories.nodes.map(
                  (blogCategory: any, bCatIndex: any) => (
                    <li
                      key={bCatIndex}
                      className="bg-white font-sofiaRegular font-normal shadow-md rounded-[31px] px-2 text-[12px]  py-1"
                    >
                      {blogCategory.name}
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </Link>
      </div>

      {blog.title ? (
        <h2
          className={`xl:text-[22px] lg:text-[20px] mt-3  text-[18px] font-sofiaMedium font-medium text-[#000000] ${
            isRelatedPage
              ? 'lg:min-h-[40px] md:min-h-[60px]'
              : 'lg:min-h-[60px]  md:min-h-[60px] '
          }   `}
        >
          {blog.title}
        </h2>
      ) : (
        <></>
      )}
      {blog.author.node.name ? (
        <h3 className="text-[12px] font-sofiaRegular font-medium  text-[#383838]">
          <span className="text-[#81878F] font-normal">
            Author <span>:</span>
          </span>{' '}
          <span>{blog.author.node.name}</span>
        </h3>
      ) : (
        <></>
      )}
      <div className="blog-content">
        <TruncatedHtml
          html={blog.content}
          showReadMore={false}
          wordCount={180}
          removeHeading={true}
        />
      </div>

      <Link
        preserveQueryParams={true}
        to={`/blog/${blog.slug}`}
        className=" bg-black hover:bg-primary text-white rounded-[31px] text-[13px] mt-3 py-3 px-5 font-sofiaRegular font-normal block w-fit "
      >
        Learn More
      </Link>
    </div>
  );
};
export default BlogCard;
